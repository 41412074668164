import React from "react";
import { Table } from "react-bootstrap";

function ParamertersTable() {
  return (
    <Table responsive striped bordered className="transparent-table">
      <thead>
        <tr>
          <th>#</th>
          <th>Item</th>
          <th>Acceptance Criteria</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>standard</td>
          <td>according to egyption standard 262-2/2021</td>
        </tr>
        <tr>
          <td>2</td>
          <td>grade</td>
          <td>
            <p>B500 CWR</p>
            <p>B500 DWR</p>
          </td>
        </tr>
        <tr>
          <td>3</td>
          <td>length</td>
          <td>12 M</td>
        </tr>
        <tr>
          <td>4</td>
          <td>diameter</td>
          <td>10 mm, 12mm, 16 mm, 18 mm, 22 mm, 25 mm, 32 mm </td>
        </tr>
        <tr>
          <td>5</td>
          <td>capacity</td>
          <td>Max Productivity 450000 ton/year </td>
        </tr>
        <tr>
          <td>6</td>
          <td>Packing</td>
          <td>about 2 ton</td>
        </tr>
      </tbody>
    </Table>
  );
}

export default ParamertersTable;
