import React from "react";
import { Table } from "react-bootstrap";

function ProductionQualityTable() {
  return (
    <Table responsive striped bordered className="transparent-table">
      <thead>
        <tr>
          <th>Standard</th>
          <th>Grade</th>
          <th>Yeild Strength(N/mm²)</th>
          <th>Tensil Strength(N/mm²)</th>
          <th>Min Elogation</th>
          <th>Ratio</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Egyption Standard 262-2/2021</td>
          <td>B400 DWR </td>
          <td>400</td>
          <td>432</td>
          <td>14</td>
          <td>1.15</td>
        </tr>
        <tr>
          <td>Egyption Standard 262-2/2021</td>
          <td>B500 DWR </td>
          <td>500</td>
          <td>575</td>
          <td>14</td>
          <td>1.25</td>
        </tr>
      </tbody>
    </Table>
  );
}

export default ProductionQualityTable;
