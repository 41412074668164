import { Table } from "react-bootstrap";

function ChemicalCompositionTable() {
  return (
    <Table responsive striped bordered className="transparent-table">
      <thead>
        <tr>
          <th></th>
          <th>Carbon</th>
          <th>Silicon</th>
          <th>Maganeses</th>
          <th>Phosphorus</th>
          <th>Sulfur</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Chemistry</td>
          <td>C %</td>
          <td>Si %</td>
          <td>Mn %</td>
          <td>P % (Max)</td>
          <td>S % (Max)</td>
        </tr>
        <tr>
          <td></td>
          <td>0.22 - 0.32</td>
          <td>0.15 - 0.30</td>
          <td>0.50 - 0.70</td>
          <td>0.055</td>
          <td>0.055</td>
        </tr>
      </tbody>
    </Table>
  );
}

export default ChemicalCompositionTable;
