import React from "react";
import { useTranslation } from "react-i18next";
import MainHeader from "../../components/MainHeader";
import PageHeader from "../../components/PageHeader";
import headerImg from "../../images/background/13.jpg";
import Footer from "../../components/Footer";
import QuickLinksCard from "../../components/QuickLinksCard";
import productReiewIcon from "../../images/icons/svg/product.svg";
import productReiewImg from "../../images/quick-links/product-review.png";

function Catalogue() {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;

  return (
    <>
      <MainHeader />
      <PageHeader image={headerImg} title={t("Catalogue")} />
      <section>
        <div
          className="container mt-5 mb-5"
          style={{ direction: currentLang === "ar" ? "rtl" : "ltr" }}
        >
          <div className="media-quickLinks d-flex justify-content-center align-items-center">
            {currentLang === "ar" ? (
              // Arabic card
              <QuickLinksCard
                icon={productReiewIcon}
                image={productReiewImg}
                title={t("Catalogue")}
                url={
                  "https://drive.google.com/file/d/19Cz6-8xajh_-xfyydhJXhylxXp4oNVAc/view?usp=sharing"
                }
              />
            ) : (
              // English card
              <QuickLinksCard
                icon={productReiewIcon}
                image={productReiewImg}
                title={t("Catalogue")}
                url={
                  "https://drive.google.com/file/d/1NjGDFYygvJ8w_69pxl7WsJ6FEbRE3niH/view?usp=sharing"
                }
              />
            )}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Catalogue;
