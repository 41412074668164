import React from "react";
import BilletParamertersTable from "./QualityTables/BilletTables/BilletParamertersTable";
import ChemicalCompositionTable from "./QualityTables/BilletTables/ChemicalCompositionTable";

function QualityBilletTables() {
  return (
    <div className="table-background">
      <div className="container">
        <ChemicalCompositionTable />
        <BilletParamertersTable />
      </div>
    </div>
  );
}

export default QualityBilletTables;
