import React from "react";
import { Table } from "react-bootstrap";

function BilletParamertersTable() {
  return (
    <Table responsive striped bordered className="transparent-table">
      <thead>
        <tr>
          <th>#</th>
          <th>Item</th>
          <th>Acceptance Criteria</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>standard</td>
          <td>according to egyption standard 262-2/2021</td>
        </tr>
        <tr>
          <td>2</td>
          <td>length</td>
          <td>6 M, 8 M, 12 M</td>
        </tr>
        <tr>
          <td>3</td>
          <td>Size</td>
          <td>
            <p>130 mm * 130 mm</p>
            <p>160 mm * 160 mm</p>
          </td>
        </tr>
        <tr>
          <td>4</td>
          <td>capacity</td>
          <td>300000 ton/year </td>
        </tr>
      </tbody>
    </Table>
  );
}

export default BilletParamertersTable;
