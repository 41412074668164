import "../styles/QualitySteelTable.css";
import Paramerters from "./QualityTables/SteelTables/ParamertersTable";
import ProductionMixWeightsTable from "./QualityTables/SteelTables/ProductionMixWeightsTable";
import ProductionQualityTable from "./QualityTables/SteelTables/ProductionQualityTable";
function QualitySteelTable() {
  return (
    <div className="table-background">
      <div className="container">
        <Paramerters />
        <ProductionQualityTable />
        <ProductionMixWeightsTable />
      </div>
    </div>
  );
}

export default QualitySteelTable;
